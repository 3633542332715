if (window) {
  //Define selectors
  const header = document.querySelector("header");
  const nav = document.querySelector("#nav");
  const navToggle = document.querySelector("#nav-toggle");
  const navInner = document.querySelector("#nav-inner");
  const subMenuClose = document.querySelectorAll(".nav__submenu-close");
  const isDesktop = window.innerWidth > 767;
  const subLinks = document.querySelectorAll(".nav__link--sublink");
  const directLinks = document.querySelectorAll(".nav__link--direct");

  //Toggle Nav
  const toggleNav = () => {
    header.classList.toggle("nav-open");
    navInner.classList.toggle("nav__inner--open");
    navToggle.classList.toggle("nav__toggle--active");
    closeSubmenu();
  };
  //Click on Burger Menu
  navToggle.addEventListener("click", () => {
    toggleNav();
  });

  const closeSubmenu = async () => {
    nav.querySelectorAll(".nav__submenu--open").forEach((openSubnav) => {
      openSubnav.classList.remove("nav__submenu--open");
    });
  };

  const checkActiveLink = (link) => {
    if (link.href === window.location.href) {
      link.classList.add("nav__item--active");
      link.closest(".nav__item--level-1").classList.add("nav__item--active");
    }
  };

  const clickOutside = (e) => {
    if (nav?.contains(e.target)) {
    } else {
      toggleNav();
      document.removeEventListener("click", clickOutside);
    }
  };

  //Handle direct Links
  directLinks.forEach((link) => {
    link.addEventListener("click", () => {
      toggleNav();
    });

    //set active class
    checkActiveLink(link);
  });

  //Handle Sublinks
  subLinks.forEach((link) => {
    //Click on sublink
    link.addEventListener("click", (e) => {
      //Close open submenus on desktop if open
      if (isDesktop) {
        closeSubmenu();
      }
      //open submenu from clicked element
      if (link && link.parentElement) {
        // @ts-ignore
        link.parentElement
          .querySelector(".nav__submenu")
          .classList.toggle("nav__submenu--open");
      }

      //Click outside directive
      setTimeout(() => {
        document.addEventListener("click", clickOutside);
      }, 500);
    });
  });

  //Handle close submenu
  subMenuClose.forEach((close) => {
    // Click on close button
    close.addEventListener("click", () => {
      if (isDesktop) {
        closeSubmenu();
      } else {
        if (close && close.parentElement) {
          close.parentElement.classList.remove("nav__submenu--open");
        }
      }
    });
  });
}
